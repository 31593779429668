const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 29 29"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2534 20.0193C16.0093 21.8124 13.1639 22.6782 10.3015 22.4387C7.43909 22.1993 4.77702 20.8728 2.86202 18.7318C0.947021 16.5908 -0.0755411 13.7978 0.00435237 10.9263C0.0842458 8.05487 1.26053 5.32301 3.29162 3.29181C5.3227 1.2606 8.05441 0.0842506 10.9257 0.00435262C13.797 -0.0755454 16.5899 0.947075 18.7307 2.86219C20.8716 4.7773 22.198 7.43952 22.4374 10.3021C22.6769 13.1646 21.8112 16.0102 20.0182 18.2544L28.6026 26.8376C28.7253 26.9519 28.8237 27.0898 28.8919 27.243C28.9601 27.3962 28.9968 27.5615 28.9998 27.7292C29.0028 27.8969 28.9719 28.0634 28.9091 28.2189C28.8463 28.3744 28.7528 28.5157 28.6343 28.6342C28.5157 28.7528 28.3745 28.8463 28.219 28.9091C28.0635 28.9719 27.8969 29.0028 27.7293 28.9998C27.5616 28.9968 27.3963 28.9601 27.2431 28.8919C27.0899 28.8236 26.9521 28.7252 26.8377 28.6025L18.2534 20.0193ZM5.05715 17.4186C3.83516 16.1964 3.00288 14.6394 2.6655 12.9443C2.32812 11.2492 2.50077 9.49212 3.16165 7.89514C3.82252 6.29815 4.94195 4.93293 6.37846 3.97201C7.81497 3.01109 9.50408 2.4976 11.2323 2.49644C12.9606 2.49527 14.6504 3.00649 16.0882 3.96548C17.526 4.92446 18.6472 6.28818 19.3102 7.88427C19.9733 9.48036 20.1483 11.2372 19.8132 12.9327C19.4781 14.6283 18.6479 16.1864 17.4276 17.4103L17.4192 17.4186L17.4109 17.4252C15.7711 19.0613 13.549 19.9796 11.2327 19.9784C8.91631 19.9771 6.69518 19.0564 5.05715 17.4186Z"
        fill="white"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default SearchIcon;
