const SmsIcon = ({ isActive, colors, ...rest }) => {
  const color = isActive ? colors.active : colors.passive;

  return (
    <div {...rest}>
      <svg
        width={50}
        height={50}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.33337 19.1387C8.33337 17.9359 8.33337 17.3345 8.63207 16.8512C8.93077 16.3679 9.46869 16.0989 10.5445 15.561L23.2112 9.22768C24.089 8.78877 24.5279 8.56932 25 8.56932C25.4722 8.56932 25.9111 8.78877 26.7889 9.22768L39.4556 15.561C40.5314 16.0989 41.0693 16.3679 41.368 16.8512C41.6667 17.3345 41.6667 17.9359 41.6667 19.1387V37.6666C41.6667 39.5522 41.6667 40.495 41.0809 41.0808C40.4951 41.6666 39.5523 41.6666 37.6667 41.6666H12.3334C10.4478 41.6666 9.50495 41.6666 8.91916 41.0808C8.33337 40.495 8.33337 39.5522 8.33337 37.6666V19.1387Z"
          stroke={color}
          strokeWidth={2}
        />
        <path
          d="M8.33337 20.8333L13.9976 26.4975C14.3727 26.8725 14.8814 27.0833 15.4118 27.0833H34.5883C35.1187 27.0833 35.6274 26.8725 36.0025 26.4975L41.6667 20.8333"
          stroke={color}
          strokeWidth={2}
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default SmsIcon;
