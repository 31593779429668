const BalanceIcon = ({ color = "#676767" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M2.14285 9.52409C2.14285 8.94069 2.14285 8.64895 2.25639 8.42617C2.35626 8.23013 2.51561 8.07082 2.71162 7.97089C2.93444 7.85742 3.22613 7.85742 3.80952 7.85742H8.15654C8.44501 7.85742 8.58925 7.85742 8.71758 7.9002C8.83119 7.93805 8.93522 7.99999 9.02272 8.08166C9.12161 8.17402 9.19043 8.30076 9.32814 8.55423L10.8399 11.3367C10.9776 11.5901 11.0465 11.7169 11.1454 11.8092C11.2329 11.891 11.3369 11.9528 11.4504 11.9907C11.5788 12.0335 11.723 12.0335 12.0115 12.0335H19.7619C20.3453 12.0335 20.637 12.0335 20.8598 12.147C21.0558 12.2469 21.2152 12.4062 21.315 12.6023C21.4286 12.8251 21.4286 13.1168 21.4286 13.7002V19.7621C21.4286 20.3455 21.4286 20.6372 21.315 20.8601C21.2152 21.056 21.0558 21.2154 20.8598 21.3153C20.637 21.4288 20.3453 21.4288 19.7619 21.4288H3.80952C3.22613 21.4288 2.93444 21.4288 2.71162 21.3153C2.51561 21.2154 2.35626 21.056 2.25639 20.8601C2.14285 20.6372 2.14285 20.3455 2.14285 19.7621V9.52409Z"
        stroke={color}
      />
      <path
        d="M19.2857 12.1427V7.857V5.23796C19.2857 4.65457 19.2857 4.36287 19.1722 4.14005C19.0723 3.94405 18.9129 3.78469 18.717 3.68482C18.4941 3.57129 18.2024 3.57129 17.619 3.57129H7.38092C6.79754 3.57129 6.50585 3.57129 6.28302 3.68482C6.08702 3.78469 5.92767 3.94405 5.8278 4.14005C5.71426 4.36287 5.71426 4.65457 5.71426 5.23796V8.10901"
        stroke={color}
      />
    </svg>
  );
};

export default BalanceIcon;
