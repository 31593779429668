const BalanceIcon = ({ color = "#676767" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 37 37"
      fill="none"
    >
      <path
        d="M21.9688 6.1665H15.0313C10.1257 6.1665 7.67292 6.1665 6.14896 7.71465C4.625 9.2628 4.625 11.7545 4.625 16.7379V20.2617C4.625 25.2452 4.625 27.7369 6.14896 29.285C7.67292 30.8332 10.1257 30.8332 15.0313 30.8332H21.9688C26.8743 30.8332 29.3271 30.8332 30.851 29.285C32.375 27.7369 32.375 25.2452 32.375 20.2617V16.7379C32.375 11.7545 32.375 9.2628 30.851 7.71465C29.3271 6.1665 26.8743 6.1665 21.9688 6.1665Z"
        stroke={color}
      />
      <path d="M10.7917 12.3335H15.4167" stroke={color} strokeLinecap="round" />
      <path
        d="M23.125 22.6665V20.4998C23.125 19.557 23.125 19.0856 23.4179 18.7927C23.7108 18.4998 24.1822 18.4998 25.125 18.4998H30.375C31.3178 18.4998 31.7892 18.4998 32.0821 18.7927C32.375 19.0856 32.375 19.557 32.375 20.4998V22.6665C32.375 23.6093 32.375 24.0807 32.0821 24.3736C31.7892 24.6665 31.3178 24.6665 30.375 24.6665H25.125C24.1822 24.6665 23.7108 24.6665 23.4179 24.3736C23.125 24.0807 23.125 23.6093 23.125 22.6665Z"
        stroke={color}
      />
    </svg>
  );
};

export default BalanceIcon;
