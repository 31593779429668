const DangerTriangleIcon = () => {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7499 7C12.7499 6.58579 12.4141 6.25 11.9999 6.25C11.5857 6.25 11.2499 6.58579 11.2499 7V13C11.2499 13.4142 11.5857 13.75 11.9999 13.75C12.4141 13.75 12.7499 13.4142 12.7499 13V7Z"
        fill="#ffaa13"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3387 1.74711C12.8499 1.0843 11.1499 1.0843 9.66118 1.74711C8.73036 2.16154 7.9787 2.95575 7.20247 4.05442C6.42869 5.14963 5.56214 6.65055 4.44265 8.58957L4.40174 8.66043C3.28224 10.5994 2.41568 12.1004 1.8541 13.3181C1.29073 14.5396 0.978757 15.5877 1.08526 16.601C1.2556 18.2217 2.10562 19.694 3.424 20.6518C4.24832 21.2507 5.31195 21.5046 6.65154 21.6275C7.98689 21.75 9.71999 21.75 11.9589 21.75H12.0408C14.2798 21.75 16.0129 21.75 17.3483 21.6275C18.6879 21.5046 19.7515 21.2507 20.5758 20.6518C21.8942 19.694 22.7442 18.2217 22.9146 16.601C23.0211 15.5877 22.7091 14.5396 22.1457 13.3181C21.5841 12.1004 20.7176 10.5994 19.5981 8.66037L19.5571 8.58948C18.4377 6.65051 17.5711 5.14961 16.7974 4.05442C16.0211 2.95575 15.2695 2.16154 14.3387 1.74711ZM10.2713 3.11743C11.3716 2.62752 12.6282 2.62752 13.7285 3.11743C14.2819 3.36379 14.8407 3.88446 15.5723 4.91996C16.3016 5.95228 17.1344 7.39321 18.2786 9.375C19.4228 11.3568 20.2543 12.7985 20.7836 13.9463C21.3146 15.0976 21.4861 15.8419 21.4228 16.4442C21.2969 17.6421 20.6686 18.7303 19.6942 19.4383C19.2041 19.7943 18.4738 20.0179 17.2113 20.1338C15.9526 20.2492 14.2883 20.25 11.9999 20.25C9.71155 20.25 8.04726 20.2492 6.78858 20.1338C5.526 20.0179 4.79569 19.7943 4.30568 19.4383C3.33123 18.7303 2.70295 17.6421 2.57705 16.4442C2.51373 15.8419 2.68524 15.0976 3.21622 13.9463C3.74556 12.7985 4.57705 11.3568 5.72123 9.375C6.86542 7.39321 7.69821 5.95228 8.42756 4.91997C9.15916 3.88446 9.71797 3.36379 10.2713 3.11743Z"
        fill="#ffaa13"
      />
      <path
        d="M13 16C13 16.5523 12.5522 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5522 15 13 15.4477 13 16Z"
        fill="#ffaa13"
      />
    </svg>
  );
};

export default DangerTriangleIcon;
