const LanguageIcon = ({ color = "#676767" }) => {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.125 12.5H8.33333M3.125 12.5C3.125 17.6777 7.32233 21.875 12.5 21.875M3.125 12.5C3.125 7.32233 7.32233 3.125 12.5 3.125M8.33333 12.5H16.6667M8.33333 12.5C8.33333 17.6777 10.1988 21.875 12.5 21.875M8.33333 12.5C8.33333 7.32233 10.1988 3.125 12.5 3.125M16.6667 12.5H21.875M16.6667 12.5C16.6667 7.32233 14.8012 3.125 12.5 3.125M16.6667 12.5C16.6667 17.6777 14.8012 21.875 12.5 21.875M21.875 12.5C21.875 7.32233 17.6777 3.125 12.5 3.125M21.875 12.5C21.875 17.6777 17.6777 21.875 12.5 21.875"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LanguageIcon;
